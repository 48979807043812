.chat-room-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 16px;
}

.room-name {
    margin-top: 0;
}

.messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
    border: 1px solid black;
    border-radius: 7px 7px 0 0;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

.new-message-input-field {
    height: 200px;
    max-height: 50%;
    font-size: 20px;
    padding: 8px 12px;
    resize: none;
}

.message-item {
    width: 55%;
    margin: 8px;
    padding: 12px 8px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}

.my-message {
    background-color: rgb(0, 132, 255);
    margin-left: auto;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
}

.send-message-button {
    font-size: 28px;
    font-weight: 600;
    color: white;
    background: #31a24c;
    padding: 24px 12px;
    border: none;
}

.messages-container, .send-message-button {
   border: none;
}
.new-message-input-field {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
    border-radius: 0 18px 18px 18px;
    height: 100px;
    font-size: 16px;
    padding: 8px 10px;
    margin-bottom: 85px;
}
.new-message-input-field:focus {
    box-shadow: none;
    outline: none;
}
.send-message-button {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background: #0062a8;
    padding: 5px 10px;
    border: none;
    position: absolute;
    bottom: 5vh;
    width: 90%;
    margin: 0 auto;
    display: table;
    left: 0;
    right: 0;
    border-radius: 5px;
    text-transform: uppercase;
}
.message-item.my-message {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff;
    border-radius: 18px 18px 0 18px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
    color: #4a4a4a;
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0 0 15px 0;
    overflow: hidden;
    position: relative;
    padding:10px 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    max-width: 100% !important;
    float: right;
    font-family: "Montserrat",sans-serif!important;
    width: 70%;
}
.message-item.received-message {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #6E48AA;
    border-radius: 18px 18px 18px 0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
    color: #4a4a4a;
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0 0 10px 0;
    overflow: hidden;
    position: relative;
    padding:10px 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    padding: 10px 12px !important;
    max-width: 100% !important;
    margin-left: 15px !important;
    margin-bottom: 10px !important;
    box-shadow: 0 0 10px -3px rgb(0 0 0 / 50%) !important;
    background: #ffffff !important;
    float: left;
    font-family: "Montserrat",sans-serif!important;
    width: 70%;
}
.chat-room-container {
    margin: 17px 17px 17px 10px;
    background: #f9fafb url(/src/img/calendar.png) no-repeat;
    margin-top: 50px;
    padding-top: 20px;
    padding-right: 10px;
}