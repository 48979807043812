body {
    font-size: 17px;
    background:#fff;
}
.header {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(28, 18, 78);
    color: white;
    padding: 20px;
}


/* new css changes */
.appointment-know-more button {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    background: #1977CC;
    color: white;
    border-radius: 4px;
    margin-top: 1rem;
    font-weight: 400;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 1rem;
}

.rsc-content div.selected-chat-item {
    background-color: #549B7D !important;
    color: #FFF !important;
    border: 1px solid #549B7D !important;
}

button:disabled,
button[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
}

.appointment-know-more {
    margin: 1rem 0.5rem;
    display: flex;
}

.asknu{
    color: #000;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    margin-left: -6px;
    position: fixed;
    bottom: 10px;
    right: 35px;
}

.main {
    display: grid;
    height: 800px;

    padding-top: 130px;
    /* grid-template-columns: 1fr 6fr; */
    grid-template-columns: repeat(auto-fit, minmax(479px, auto));
}
.nav-btn1 {
    position: absolute;
    top: 10px!important;
    z-index: 999;
    cursor: pointer;
    margin-right: 10px!important;
    margin-left: 10px!important;
}

.nav {
    background-color: rgba(115, 110, 192, 0.466);
    text-align: center;
}

.content {
    background-color: rgba(160, 157, 195, 0.466);
}


.footer {
    height: 400px;
    text-align: center;
    padding: 20px;
    background-color: rgb(223, 223, 223);
}

.bkhdnw{
    bottom:20px !important;
}

.bot {
    bottom: 5px;
    right : 30px;
    position: fixed;
    z-index: 99999 !important;
}

.btn1 {
    font-size: 20px;
    width:70px;
    position: fixed;
    bottom: 20px;
    right:20px;
    float: right;
    padding: 8px;
}

.rsc-cs {
    background: transparent !important;
    box-shadow: 0 0 0 !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    text-transform: inherit;
}


.chat-item-long {
    color: #202020;
    background: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    box-shadow: -2px 9px 11px -13px rgb(0 0 0 / 75%);
    cursor: pointer;
    font-size:16px!important;
    border-top: 3px solid #18a2b8 !important;
    border-top-right-radius: 20px;
    border-right: 3px solid#18a2b8;
    border-bottom: 3px solid#18a2b8;
    border-bottom-left-radius: 20px;
    border-left: 3px solid#18a2b8;
}

.calender-icon {
    width: 25px!important;
    margin-left: 10px;
}
.header-title {
    font-family: Muli,sans-serif!important;
    font-weight: 600;
    margin-left: 5px;
    font-size: 14px!important;
    font-weight: bold!important;
}

.apo-sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apo-date {
    flex: 1;
    font-size: 13px;
}


.search-icon {
    width: 10px;
}
.search-feild{
    flex: 1;
    font-size: 12px;
    border: none
}

.doc-container {
    overflow: hidden;
    position: relative;

    overflow: hidden;
    white-space: nowrap;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.doc-card {
    display: inline-block;
    border-top: 1px solid #ddd!important;
    height: 367px;
    width: 100%;
    padding: 13px 0;

}

.doc-top {
    display: flex;
    background: #f8f9f9 !important;
    margin-top: -11px !important;
    box-shadow: -2px 9px 6px -10px rgba(0,0,0,0.75);
    margin:4px;
}
.doc-img {
    width: 60px;
    height: 60px;

    border-radius: 20px!important;
}
.doc-meta {
    width: 160px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 20px;
}
.doc-meta div {
    margin: 0;
    padding: 0;
}
.doc-meta .dep {
    font-size: 15px!important;
    font-weight: bold;
    color: #000!important;
    padding-top: 5px;
}
.doc-meta .name {
    font-size: 12px;
}
.doc-meta .exp {
    font-size: 10px!important;
    font-weight: bold;
}

.slots {
    padding: 0px 15px 0 0px!important;

}
.date-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;

}
.date-feild {
    font-size: 15px;
    width: 160px;
    border: 1px solid #b9b9b9!important;
    border-radius: 5px;
    box-shadow: -2px 10px 5px -12px rgba(0,0,0,0.75);
}


.tab-container {
    text-align: center;

}
.tab {
    width: 94%;
    margin: 10px auto;
    border-radius: 5px;
    overflow: hidden;
    background: #74b9ff;
    display: flex;

}
.tab .item {
    flex: 1;
    text-align: center;
    padding: 5px 6px;
    font-size: 13px !important;
    cursor: pointer;

}
.tab .item.active {
    border: 1px solid #151617!important;
    box-sizing: border-box;
    border-radius: 5px;
    color: #200220;
    background: #17a2b8!important;
    font-size: 11px;
}
.XYUYI .input-submit input {
    width: 280px !important;
}

.slots-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.slots-area .item {
    margin-bottom: 15px;
    width: 40%;
    background: #70ea75;
    border-radius: 5px;
    padding: 5px 0;
    text-align: center;
    font-size: 11px !important;
    cursor: pointer;
    font-size: 12px  !important;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 12px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
}
.dejyBq {
    max-width: 100% !important;
    margin: -8px 0px 10px 15px !important;
}
.prev {
    left: -36px;
    margin-top: -17px;
}
.next {
    right: -30px;
    margin-top: -17px;

}


.kATXlE, .dmkhbB {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    border-radius: 18px 18px 58px 0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
    font-family: Muli,sans-serif!important;
    font-size: 13px !important;
    max-width:55% !important;
    margin: 0 0 21px 0 !important;
    background: #9bbaf3 !important;
    color:#000!important;
}
* {
    font-family: Muli,sans-serif;
}
.kHmQgG {
    background: #f5f5f5!important;
}
.apo-header {
    align-items: center;
    display: flex;
    box-shadow: 0 0 9px -0.5px rgb(0 0 0 / 23%)!important;
    left: 0px;
    background-color: #17a2b8!important;
    height:35px!important;
    color: #fff;
    align-items: center;
    display: flex;
}
.search-icon {
    width: 18px;
    margin-top: 4px;
}
.search-icon-container {
    background: #ecebeb!important;
    padding: 5px 13px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 1px solid #bfbfbf;
}

.empty_container{
    width: 40px ;
}
.full_icon{
    background:#505050;
    display: flex;
}
.search-box {
    overflow: hidden;
    border: 1px solid #cbcbce;
    display: flex;
    margin-top: 10px;
    margin-right: 45px;
    border-radius: 10px;
    height:38px;
}
.appoinment-container {
    width: 100%;
    position: relative;
    background: #f8f9f9!important;
    width: 100%!important;
    height: 474px !important;
    border: 1px solid #e8e8e8!important;
    box-shadow: -2px 9px 5px -10px rgba(0,0,0,0.75);
}

.react-datepicker__day--disabled{
    cursor: default;
    color: #000 !important;
    border: none !important;
}


.react-datepicker {
    font-family: Muli,sans-serif!important;
    font-size: 0.8rem;
    background-color: #085d6b!important;
    color: #000;
    border: 1px solid #000!important;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker__header {
    text-align: center;
    background-color: #17a1b7!important;
    border-bottom: 1px solid #f7feff!important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 9px;
    position: relative;
}
.react-datepicker__day--selected{
    border-radius: 19.3rem;
    background-color: #0ab176 !important;
    color: #080808;
}
.react-datepicker__triangle{
    border-top: none;
    border-bottom-color: #000!important;
}

.react-datepicker__current-month{
    margin-top: 0;
    color: #000!important;
    /* font-weight: bold; */
    font-size: 0.944rem;

}
.react-datepicker__day-name{

    color: #fff!important;
    display: inline-block;
    width: 1.7rem;
    line-height: 19px!important;
    text-align: center;
    margin: 0.166rem;
}
.tab .item.active {
    border: 3px solid #216ba5 !important;
    box-sizing: border-box;
    border-radius: 5px;
    color: #000 !important;
    background: #abd4da !important;
    font-size: 11px;
    border-radius: 20px !important;
}
.tab .item {
    color: #000;
}
.tab {
    /* width: 94%; */
    margin: 4px auto;
    border-radius: 5px;
    overflow: hidden;
    background: #abd4da;
    display: flex;
    /* border: 1px solid #000; */
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
    /*height: 32px;*/
}
.calender-icon {
    margin-right: 10px;
}
.slots-area .item {
    background: #a3e6a6;
    color: #000;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}
.react-datepicker__header {
    background-color: #abd4da !important;
    border-bottom: 1px solid #aeaeae;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    color: #000;
}
.react-datepicker__day-name {
    color: #000 !important;
    font-weight: 600;
}
.react-datepicker {
    background-color: #fff !important;
    color: #000;
}
.react-datepicker__day--selected {
    background-color: transparent !important;
    color: #080808;
    border: 3px solid #0151a6 !important;
}

.fkcGjN, .inbydY {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #e0dfdf;
    border-radius: 18px 18px 0 18px;
    /* box-shadow: 0 1px 2px 0 rgb(0 0 0); */
    color: #4a4a4a;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: 0 0 13px 0;
    overflow: hidden;
    position: relative;
    padding: 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.doc-meta {
    text-transform: uppercase;
}
.apo-date {
    font-size: 15px;
    margin-left: 1rem;
    margin-top: 10px;
}
.nav-btn1 {
    position: absolute;
    top: 28px;
    z-index: 999;
    cursor: pointer;
}
.doc-meta {
    width: 160px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
}

.fGPkxk, .gBpIQl {
    background: #9bbaf3 !important;
    border: 0;
    border-radius: 18px 18px 18px 0!important;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    padding: 12px;
    margin-left: 54px!important;
}


.dJJgye, .hjdLDW {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    border-radius: 50% 50% 0 50%;
    box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
    height: 51px !important;
    min-width: 40px;
    padding: 3px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}

.brrKeP, .dejyBq {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    max-width: 100% !important;
    margin: -8px 0 10px 10px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.dejyBq {
    margin: -20px 15px 10px 15px !important;
}
.gWDxQk .input-submit input {
    width: 240px !important;
}
.dejyBq  {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    max-width: 100% !important;
    margin: -8px 0 10px 46px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}



.wcuuO {
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 5px solid #17a2b8 !important;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    opacity: .5;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
}
.fzjmLC, .bgPCoD {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px!important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: -20px 0 0px 15px!important;
    overflow: hidden;
    position: relative;
    padding: 10px!important;;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.bgPCoD {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px!important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: -20px 0 15px 15px!important;
    overflow: hidden;
    position: relative;
    padding: 10px!important;;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.kNrYDJ {
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 8px 6px 10px 6px!important;
    padding: 16px;
}
.hrBZJS {
    background: #f3f1f1!important;
    border-radius: 10px;
    box-shadow: 9px 9px 10px -11px rgba(0,0,0,0.75);
    font-family: monospace;
    overflow: hidden;
    position: relative;
    bottom: initial;
    top: initial;
    right: initial;
    left: initial;
    width: 400px;
    height: 625px;
    z-index: 999;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
}
.eDOvDv, .jgsmDM {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #17a2b8!important;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    fill: #fff;
    height: 56px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 10px;
    box-shadow: 9px 9px 10px -13px rgba(0,0,0,0.75);
}
.jVMajw {
    width: 420px !important;
    height: 620px !important;
    bottom: 20px !important;
    right: 20px !important;
}
.jeOWIe {
    width: 420px !important;
    height: 620px !important;
    bottom: 20px !important;
    right: 20px !important;
}

.gxaVzD {
    background: #f0f0f0;
}
.HrDCL {
    height: calc(570px - 112px) !important;
    background-image: url(../src/img/getimage.png) !important;
    background-size: 100% !important;
}
.hUMRbp {
    height: calc(570px - 112px) !important;
    background-image: url(../src/img/getimage.png) !important;
    background-size: 100% !important;
}
.gxaVzD {
    height: calc(620px - 112px) !important;
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
}
.brrKeP {
    margin: -20px 15px 10px 15px !important;
}
.fGPkxk, .gBpIQl {
    margin-left: 0px!important;
}
.enhgbB {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: -10px 15px 12px 15px !important;
    background: #fff;
    display: inline-block;
    padding: 10px 15px !important;
    border-radius: 10px !important;
}
.fxWvvr {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: -10px 10px 12px 15px !important;
    background: #fff;
    display: inline-block;
    padding: 10px 20px !important;
    border-radius: 10px !important;
}

@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
}

@keyframes shadow-pulse-big
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
    }
}

.bDElFG {
    animation: shadow-pulse 1s infinite;
}
.jrcSyQ {
    animation: shadow-pulse 1s infinite;
}
.chat-item {
    background: #fff!important;
    color: #000!important;
    font-size: 13px!important;
    padding: 10px 10px;
    border-radius: 18px;
    margin-right: 10px!important;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.35)!important;
    text-transform: uppercase;
    font-weight: 600;
}
.slots-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    padding: 10px 0;
}
.react-datepicker__day--selected {
    color: #000 !important;
    box-shadow: none !important;
}
.react-datepicker__day--selected:focus {
    outline:none;
}
.prev {
    margin-top: 10px;
}
.next {
    margin-top: 10px;
}
.doc-img {
    margin-left: 0;
}
.hBoqZI {
    font-size: 18px !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.hVbQid {
    font-size: 18px !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.resetStyle {
    cursor: pointer;
    display: block;
    position: relative;
    top: -600px;
    left: -40px;
    fill: #FFF;
    z-index: 10000;
    animation-delay: 5s;
    transition-delay: 5s;
}
.logoStyle {
    cursor: pointer;
    display: block;
    position: relative;
    top: -578px;
    z-index: 10000;
    left: -370px;
    fill: #FFF;
    animation-delay: 5s;
    transition-delay: 5s;
    display: none;
}
.rsc .rsc-header-title {
    position: relative;
}
.rsc .rsc-header-title:before  {
    position: absolute;
    content: "";
    left: -35px;
    top: -3px;
    background: url(../src/img/hospital-icn.png) no-repeat;
    background-size: contain;
    z-index: 9999999;
    width: 23px;
    height: 23px;

}
.react-datepicker__day {
    border: 1px solid #000;
    border-radius: 50%;

}
.fGPkxk:focus {
    outline: none;
}
button.book-btn {
    background: #17a2b8;
    border: 1px solid #17a2b8;
    padding: 7px 20px;
    border-radius: 30px;
    margin: 50px auto;
    display: table;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.appoinment-container {
    position: relative;
    background: #dee2e6!important;
    width: 100%!important;
    height: 474px !important;
    border: 1px solid #e8e8e8!important;
    box-shadow: -2px 9px 5px -10px rgba(0,0,0,0.75);
    border-radius: 10px;
    border: 2px solid #18a2b8;
}
.apo-header {
    align-items: center;
    display: flex;
    box-shadow: 0 0 9px -0.5px rgb(0 0 0 / 23%)!important;
    left: 0px;
    background-color: #abd4da !important;
    height: 50px!important;
    color: #000;
    align-items: center;
    display: flex;
    border-radius: 10px 10px 0 0;
}
.header-title {

    font-size: 16px!important;
}
.kATXlE, .fzjmLC, .brrKeP, .fGPkxk, .fkcGjN, .chat-item  {
    font-family: 'Montserrat', sans-serif !important;
}
.kATXlE, .bgPCoD, .dejyBq, .gBpIQl, .inbydY, .chat-item  {
    font-family: 'Montserrat', sans-serif !important;
}
.kATXlE {
    padding: 10px 12px !important;
    max-width: 100% !important;
    margin-left: 15px !important;
    margin-bottom: 0px !important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    background: #ffffff !important;
}
.dmkhbB {
    padding: 10px 12px !important;
    max-width: 100% !important;
    margin-left: 15px !important;
    margin-bottom: 5px !important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    background: #ffffff !important;
}

.header-title {
    font-family: 'Montserrat', sans-serif !important;
}
.doc-meta .dep {
    font-family: 'Montserrat', sans-serif !important;
}
.doc-meta .name {
    font-family: 'Montserrat', sans-serif !important;
    font-weight:600 !important;
}
.doc-meta .qu {
    font-size:10px !important;
    font-family: 'Montserrat', sans-serif !important;
}
.doc-meta .exp, .date-feild {
    font-family: 'Montserrat', sans-serif !important;
}
.tab .item {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
}
.slots-area .item {
    font-family: 'Montserrat', sans-serif !important;
}
.chat-item-long h4 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
}
.search-feild {
    padding: 0 10px;
}
.search-feild:focus {
    outline: none;
    border: none;
}

.react-datepicker__day {
    color: #fff;
    /* display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem; */
}
.kATXlE {
    font-size: 14px !important;
}
.dmkhbB {
    font-size: 14px !important;
}
.fzjmLC, .brrKeP, .fkcGjN, .brrKeP  {
    font-size: 14px !important;
}
.bgPCoD, .brrKeP, .inbydY, .brrKeP  {
    font-size: 14px !important;
}
.fGPkxk {
    font-size: 13px !important;
    border-radius: 18px 18px 18px 18px !important;
    float: left;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #000 !important;
}
.gBpIQl {
    font-size: 12px !important;
    border-radius: 18px 18px 18px 18px !important;
    float: left;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #000 !important;
}
.fGPkxk:hover {
    background: #00adee !important;
    color: #fff !important;
    border: 1px solid #00adee !important;
}
.gBpIQl:hover {
    background: #00adee !important;
    color: #fff !important;
    border: 1px solid #00adee !important;
}
.gBpIQl {
    font-size: 11px !important;
    border-radius: 18px 18px 18px 18px !important;
}
.eSbheu {
    float: left;
}
.react-datepicker__day {
    color: #000;
}
.iRzrAb {
    display: block !important;
}
.dTodAw {
    display: block !important;
    padding: 5px;
    margin: 0px 5px 5px 10px;
}
.search-box {
    width: 65%;
}
.kATXlE {
    display: inline-table;
}
.dmkhbB {
    display: inline-table;
}
.bDElFG {
    width: 75px !important;
    height: 75px !important;
    background:url(../src/img/pop-icon-04.png) !important;
    background-size: contain !important;
    bottom: 40px !important;
}
.jrcSyQ {
    background: #0062a8 !important;
}
select {
    color: #fff !important;
    width: 100%;
    background: #0062a8;
    padding: 10px;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid #0062a8;
    /* margin: 0 auto; */
    /* display: table; */
    font-size: 15px !important;
    font-weight: 600 !important;
}
select:focus {
    outline: none;
}
select option {
    width: 300px;
    font-size: 13px;
}
.eDOvDv {
    height: 46px !important;
    /* animation: myanimation 10s infinite; */
}
/* @keyframes myanimation {
  0% {background-color: red;}
  25%{background-color:yellow;}
  50%{background-color:green;}
  75%{background-color:brown;}
  100% {background-color: red;}
} */

.privacy-policy {

    width: 100%;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
}
.iWmPrn .input-submit input {
    width: 230px !important;
}
.doc-card {
    margin-bottom: 20px;
}
.contact-sect button.btn-cnt {
    border: none !important;
    background-color: transparent;
    padding: 0;
    text-transform: capitalize;
}
.contact-sect button.btn-cnt:focus {
    border: none !important;
    box-shadow: none;
    outline: none;
}
.logo-header img {
    margin-right: 10px;
    width: 35px;
    margin-left: 5px;
}
.rsc-float-button svg {
    display: none;
}
.dhsUgD {
    float: left;
}
.gBpIQl {
    float: left;
}
.eVthjO {
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;
}
.eVthjO svg {
    fill: #000 !important;
}
.eVthjO .logo-header h5 {
    color: #000;
}
.chat-item {
    text-align: center;
    margin-left: 0px;
    float: left;
    text-transform: capitalize;
    font-size: 11px !important;
    margin-bottom: 5px;
    padding: 4px 7px;
    border-radius: 18px;
    margin-right: 5px!important;
    box-shadow: none !important;
    border: 1px solid #000;
    font-weight: 100;
}
.location-main-div {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 12px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.kNrYDJ {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 15px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.bJCmFu {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 15px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.location-div {
    display: contents;
}
.flag-select {
    padding-bottom: 1px !important;
    text-align: left;
    border: 1px solid #1782b8;
    border-right: none;
    border-radius: 4px 0px 0 4px;
}
.flag-select__option {
    margin: 1px 0 7px 0 !important;
}
.iNniHT .input-submit input {
    text-transform: capitalize;
}

.cYTrag .logo-header h5 {
    color: #000;
}

.cYTrag{
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;
}

.cYTrag svg {
    fill: #000 !important;

}
.kuqxww .input-submit input {
    width: 100%;
    text-transform: capitalize !important;
}
.iWmPrn .input-submit input {
    text-transform: capitalize !important;
}

.jYxGEJ{
    background-color: #1782B8;
    color: #fff;
    padding: 0.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;

}

.jrcSyQ{

    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background: #6e48aa;
    bottom: 32px;
    border-radius: 100%;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.15);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    fill: #fff;
    height: 56px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    right: 32px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    width: 56px;
    z-index: 999;
}



.jrcSyQ{
    width: 75px !important;
    height: 75px !important;
    background: url(img/pop-icon-04.png) !important;
    background-size: contain !important;
    bottom: 40px !important;
    z-index: 99999 !important;
}

.jYxGEJ .logo-header h5{
    position: relative;
    top: 6px;
    font-family: 'Poppins';
    font-weight: bold;
}
.jYxGEJ .logo-header h5{
    color: #000;
}

svg {
    overflow: hidden;
    vertical-align: middle;
    fill: #000 !important;
}
.privacy-policy {
    border-radius: 10px 10px 10px 10px;
}

.dJJgye {
    width: 20px !important;
    height: 20px !important;
    display: block;
    padding: 0 !important;
    min-width: auto !important;
}
.hjdLDW {
    width: 20px !important;
    height: 20px !important;
    display: block;
    padding: 0 !important;
    min-width: auto !important;
}
.gOCww {
    display: block !important;
}
.jmCkbN {
    display: block !important;
    padding: 0 0 5px 0;
    margin: 5px 10px;
}
.loader-div {
    box-shadow: none !important;
    background: transparent !important;
}
.bJCmFu {

}

.slots-area .item {
    width: 45%;
    padding: 5px 3px;
    margin: 5px;
}
.bgPCoD, .brrKeP, .chat-item, .dejyBq, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-size: 14px !important;
}
.chat-item{
    font-size: 12px !important;
    text-transform: uppercase !important;
}
select option {
    font-size: 14px;
}
.cUWXFh textarea {
    height: 100px;
}
.cUWXFh button {
    background: #00aeef;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #00aeef;
}
.bgPCoD, .brrKeP, .dmkhbB, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-size: 14px!important;
    font-family: "Montserrat",sans-serif!important;
}
.gBpIQl {
    font-size: 12px !important;
}
.bgPCoD, .brrKeP, .dmkhbB, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-family: "Montserrat",sans-serif!important;
}
@media only Screen and (max-width: 1370px) {
    .jeOWIe {
        height: 510px !important;
    }
    .gxaVzD {
        height: calc(510px - 112px) !important;
    }
    .resetStyle {
        top: -510px;
    }
    .jVMajw {
        height: 510px !important;
    }

}
@media only Screen and (max-width: 1920px) {
    .jVMajw {
        height: 455px !important;
    }
    .bkhdnw{
        height:  521px!important
    }
}
@media only Screen and (max-width: 767px) {
    .kuqxww .input-submit input {
        width: 150px !important;
    }
    .fTBrVP .input-submit input {
        width: 170px !important;
    }
    .XYUYI .input-submit input {
        width: 170px !important;
    }
    .slots-area .item {
        margin-bottom: 15px;
    }
    .jeOWIe {
        width: 330px !important;
        border-radius: 20px !important;
        bottom: 10px !important;
        right: 10px !important;
    }
    .resetStyle {
        top: -490px;
        left: -15px;
    }
    .jVMajw {
        width: 310px !important;
        height: 476px !important;
        bottom: 20px !important;
        right: 20px !important;
        border-radius: 10px !important;
    }

    .fzjmLC, .brrKeP, .fkcGjN, .brrKeP {
        font-size: 13px !important;
    }
    .kATXlE {
        font-size: 13px !important;
    }
    .doc-meta .dep {
        font-size: 12px !important;
        font-weight: 600;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .doc-meta .qu {
        font-size: 9px!important;
        font-weight: bold;
    }
    .doc-meta .name {
        font-size: 10px;
    }
    .fGPkxk {
        font-size: 12px !important;
    }

    .appoinment-container {
        height: auto !important;
    }
    .chat-item-long h4 {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 15px;
    }
    .doc-img {
        width: 70px;
        height: 70px;
        border-radius: 10px!important;
        margin-left: 25px;
    }
    .prev {
        margin-top: -20px;
    }

    .next {
        margin-top: -20px;
    }
    .search-box {
        width: 60%;
    }
    .empty_container {
        width: 15px;
    }
    .doc-card {
        margin-bottom: 0px !important;
    }
}
@media screen and (max-width: 568px) {
    .jeOWIe {
        bottom: 10px !important;
        right: 10px !important;
    }
    .sc-fzoLsD.bkhdnw.rsc-container {
        z-index: 16000163 !important;
    }
}
@media only Screen and (max-width: 320px) {
    .chat-item {
        font-size: 10px!important;
        text-transform: uppercase !important;
    }
    .resetStyle {
        top: -360px;
        left: -15px;
    }

    .slots-area .item {
        margin-bottom: 15px;
        width: 60%;
    }
    .copy_rights{
        font-size: 8px;
    }
    .jVMajw {
        width: 290px !important;
        height: 420px !important;
        bottom: 20px !important;
        right: 20px !important;
        border-radius: 10px !important;
    }

    .fzjmLC, .brrKeP, .fkcGjN, .brrKeP {
        font-size: 13px !important;
    }
    .kATXlE {
        font-size: 13px !important;
    }
    .fGPkxk {
        font-size: 12px !important;
    }
    .doc-meta .dep {
        font-size: 10px !important;;
    }
    .jeOWIe {
        width: 275px !important;
        height: 400px !important;
        border-radius: 10px;
        bottom: 10px !important;
        right: 10px !important;
    }

    .gxaVzD {
        height: calc(400px - 112px) !important;
    }
    .sc-fzoLsD.bkhdnw.rsc-container {
        z-index: 16000163 !important;
    }
}

.anchor{
    display: block;
    height: 115px; /*same height as header*/
    margin-top: -115px; /*same height as header*/
    visibility: hidden;
}

.loader_issue{
    background: #000 !important;
}

.infoText{
    color:#5f6368;
    font-size: 10px;
    text-transform:initial;
    text-align: left;
    float: left;
}
.surButton{
    display: table;
    margin: 5px auto;
    padding: 5px 10px !important;
    margin-top: 30px;
}
.inputTextArea{
    /* width:320px !important; */
}

.react-switch-bg
{
    margin-bottom: 10px !important;
}

.cxzqCi {
    border-bottom: 2px solid #c4cad0 !important;
    border-radius: 0 !important;
}



/*.copy_rights {*/
/*    z-index: 999999;*/
/*    position: fixed;*/
/*    right: 34px;*/
/*    bottom: 21px;*/
/*    font-size: 10px;*/
/*    color: #000;*/
/*    text-align: right;*/
/*    font-weight: 500;*/
/*    width: 100%;*/
/*    padding-bottom: 4px;*/
/*}*/

.HrDCL, .hUMRbp {
    height: 465px !important;
}

.gyJkjv {
    border-bottom: 2px solid #c4cad0 !important;
    border-radius: 0 !important;
}

.cYTrag .logo-header h5 {
    top: 0 !important;
    margin: 0 !important;
}

.new-message-input-field {
    margin-bottom: 135px !important;
}.send-message-button {
     bottom: 10vh;
 }

@media only Screen and (max-width: 1610px) {
    .send-message-button {
        bottom: 12vh !important;
    }
}
@media only Screen and (max-width: 1370px) {
    .send-message-button {
        bottom: 15vh !important;
    }
}


.sc-fzoXzr.cYTrag{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}



.footer_hide{
    display: none;
}ody {
     font-size: 17px;
     background:#fff;
 }
.header {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(28, 18, 78);
    color: white;
    padding: 20px;
}


/* new css changes */
.appointment-know-more button {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    background: #1977CC;
    color: white;
    border-radius: 4px;
    margin-top: 1rem;
    font-weight: 400;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 1rem;
}

.rsc-content div.selected-chat-item {
    background-color: #549B7D !important;
    color: #FFF !important;
    border: 1px solid #549B7D !important;
}

button:disabled,
button[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
}

.appointment-know-more {
    margin: 1rem;
    display: flex;
}

.asknu{
    color: #000;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    margin-left: -6px;
    position: fixed;
    bottom: 10px;
    right: 35px;
}

.main {
    display: grid;
    height: 800px;

    padding-top: 130px;
    /* grid-template-columns: 1fr 6fr; */
    grid-template-columns: repeat(auto-fit, minmax(479px, auto));
}
.nav-btn1 {
    position: absolute;
    top: 10px!important;
    z-index: 999;
    cursor: pointer;
    margin-right: 10px!important;
    margin-left: 10px!important;
}

.nav {
    background-color: rgba(115, 110, 192, 0.466);
    text-align: center;
}

.content {
    background-color: rgba(160, 157, 195, 0.466);
}


.footer {
    height: 400px;
    text-align: center;
    padding: 20px;
    background-color: rgb(223, 223, 223);
}

.bkhdnw{
    bottom:20px !important;
}

.bot {
    bottom: 5px;
    right : 30px;
    position: fixed;
    z-index: 99999 !important;
}

.btn1 {
    font-size: 20px;
    width:70px;
    position: fixed;
    bottom: 20px;
    right:20px;
    float: right;
    padding: 8px;
}

.rsc-cs {
    background: transparent !important;
    box-shadow: 0 0 0 !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    text-transform: inherit;
}


.chat-item-long {
    color: #202020;
    background: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    box-shadow: -2px 9px 11px -13px rgb(0 0 0 / 75%);
    cursor: pointer;
    font-size:16px!important;
    border-top: 3px solid #18a2b8 !important;
    border-top-right-radius: 20px;
    border-right: 3px solid#18a2b8;
    border-bottom: 3px solid#18a2b8;
    border-bottom-left-radius: 20px;
    border-left: 3px solid#18a2b8;
}

.calender-icon {
    width: 25px!important;
    margin-left: 10px;
}
.header-title {
    font-family: Muli,sans-serif!important;
    font-weight: 600;
    margin-left: 5px;
    font-size: 14px!important;
    font-weight: bold!important;
}

.apo-sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apo-date {
    flex: 1;
    font-size: 13px;
}


.search-icon {
    width: 10px;
}
.search-feild{
    flex: 1;
    font-size: 12px;
    border: none
}

.doc-container {
    overflow: hidden;
    position: relative;

    overflow: hidden;
    white-space: nowrap;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.doc-card {
    display: inline-block;
    border-top: 1px solid #ddd!important;
    height: 367px;
    width: 100%;
    padding: 13px 0;

}

.doc-top {
    display: flex;
    background: #f8f9f9 !important;
    margin-top: -11px !important;
    box-shadow: -2px 9px 6px -10px rgba(0,0,0,0.75);
    margin: 4px;
}
.doc-img {
    width: 60px;
    height: 60px;

    border-radius: 20px!important;
}
.doc-meta {
    width: 160px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 20px;
}
.doc-meta div {
    margin: 0;
    padding: 0;
}
.doc-meta .dep {
    font-size: 15px!important;
    font-weight: bold;
    color: #000!important;
    padding-top: 5px;
}
.doc-meta .name {
    font-size: 12px;
}
.doc-meta .exp {
    font-size: 10px!important;
    font-weight: bold;
}

.slots {
    padding: 0px 15px 0 0px!important;

}
.date-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;

}
.date-feild {
    font-size: 15px;
    width: 160px;
    border: 1px solid #b9b9b9!important;
    border-radius: 5px;
    box-shadow: -2px 10px 5px -12px rgba(0,0,0,0.75);
}


.tab-container {
    text-align: center;

}
.tab {
    width: 94%;
    margin: 10px auto;
    border-radius: 5px;
    overflow: hidden;
    background: #74b9ff;
    display: flex;

}
.tab .item {
    flex: 1;
    text-align: center;
    padding: 5px 6px;
    font-size: 13px !important;
    cursor: pointer;

}
.tab .item.active {
    border: 1px solid #151617!important;
    box-sizing: border-box;
    border-radius: 5px;
    color: #200220;
    background: #17a2b8!important;
    font-size: 11px;
}
.XYUYI .input-submit input {
    width: 280px !important;
}

.slots-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.slots-area .item {
    margin-bottom: 15px;
    width: 40%;
    background: #70ea75;
    border-radius: 5px;
    padding: 5px 0;
    text-align: center;
    font-size: 11px !important;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 12px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
}
.dejyBq {
    max-width: 100% !important;
    margin: -8px 0px 10px 15px !important;
}
.prev {
    left: -36px;
    margin-top: -17px;
}
.next {
    right: -30px;
    margin-top: -17px;

}


.kATXlE, .dmkhbB {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    border-radius: 18px 18px 58px 0;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
    font-family: Muli,sans-serif!important;
    font-size: 13px !important;
    max-width:55% !important;
    margin: 0 0 21px 0 !important;
    background: #9bbaf3 !important;
    color:#000!important;
}
* {
    font-family: Muli,sans-serif;
}
.kHmQgG {
    background: #f5f5f5!important;
}
.apo-header {
    align-items: center;
    display: flex;
    box-shadow: 0 0 9px -0.5px rgb(0 0 0 / 23%)!important;
    left: 0px;
    background-color: #17a2b8!important;
    height:35px!important;
    color: #fff;
    align-items: center;
    display: flex;
}
.search-icon {
    width: 18px;
    margin-top: 4px;
}
.search-icon-container {
    background: #ecebeb!important;
    padding: 5px 13px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 1px solid #bfbfbf;
}

.empty_container{
    width: 40px ;
}
.full_icon{
    background:#505050;
    display: flex;
}
.search-box {
    overflow: hidden;
    border: 1px solid #cbcbce;
    display: flex;
    margin-top: 10px;
    margin-right: 45px;
    border-radius: 10px;
    height:38px;
}
.appoinment-container {
    width: 100%;
    position: relative;
    background: #f8f9f9!important;
    width: 100%!important;
    height: 474px !important;
    border: 1px solid #e8e8e8!important;
    box-shadow: -2px 9px 5px -10px rgba(0,0,0,0.75);
}

.react-datepicker__day--disabled{
    cursor: default;
    color: #000 !important;
    border: none !important;
}


.react-datepicker {
    font-family: Muli,sans-serif!important;
    font-size: 0.8rem;
    background-color: #085d6b!important;
    color: #000;
    border: 1px solid #000!important;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker__header {
    text-align: center;
    background-color: #17a1b7!important;
    border-bottom: 1px solid #f7feff!important;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 9px;
    position: relative;
}
.react-datepicker__day--selected{
    border-radius: 19.3rem;
    background-color: #0ab176 !important;
    color: #080808;
}
.react-datepicker__triangle{
    border-top: none;
    border-bottom-color: #000!important;
}

.react-datepicker__current-month{
    margin-top: 0;
    color: #000!important;
    /* font-weight: bold; */
    font-size: 0.944rem;

}
.react-datepicker__day-name{

    color: #fff!important;
    display: inline-block;
    width: 1.7rem;
    line-height: 19px!important;
    text-align: center;
    margin: 0.166rem;
}
.tab .item.active {
    border: 3px solid #216ba5 !important;
    box-sizing: border-box;
    border-radius: 5px;
    color: #000 !important;
    background: #abd4da !important;
    font-size: 11px;
    border-radius: 20px !important;
}
.tab .item {
    color: #000;
}
.tab {
    /* width: 94%; */
    margin: 4px auto;
    border-radius: 5px;
    overflow: hidden;
    background: #abd4da;
    display: flex;
    /* border: 1px solid #000; */
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 49%);
    /*height: 32px;*/
}
.calender-icon {
    margin-right: 10px;
}
.slots-area .item {
    background: #a3e6a6;
    color: #000;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}
.react-datepicker__header {
    background-color: #abd4da !important;
    border-bottom: 1px solid #aeaeae;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    color: #000;
}
.react-datepicker__day-name {
    color: #000 !important;
    font-weight: 600;
}
.react-datepicker {
    background-color: #fff !important;
    color: #000;
}
.react-datepicker__day--selected {
    background-color: transparent !important;
    color: #080808;
    border: 3px solid #0151a6 !important;
}

.fkcGjN, .inbydY {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #e0dfdf;
    border-radius: 18px 18px 0 18px;
    /* box-shadow: 0 1px 2px 0 rgb(0 0 0); */
    color: #4a4a4a;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: 0 0 13px 0;
    overflow: hidden;
    position: relative;
    padding: 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.doc-meta {
    text-transform: uppercase;
}
.apo-date {
    font-size: 15px;
    margin-left: 1rem;
    margin-top: 10px;
}
.nav-btn1 {
    position: absolute;
    top: 28px;
    z-index: 999;
    cursor: pointer;
}
.doc-meta {
    width: 160px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
}

.fGPkxk, .gBpIQl {
    background: #9bbaf3 !important;
    border: 0;
    border-radius: 18px 18px 18px 0!important;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    padding: 12px;
    margin-left: 54px!important;
}


.dJJgye, .hjdLDW {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    border-radius: 50% 50% 0 50%;
    box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
    height: 51px !important;
    min-width: 40px;
    padding: 3px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}

.brrKeP, .dejyBq {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    max-width: 100% !important;
    margin: -8px 0 10px 10px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.dejyBq {
    margin: -20px 15px 10px 15px !important;
}
.gWDxQk .input-submit input {
    width: 240px !important;
}
.dejyBq  {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px !important;
    max-width: 100% !important;
    margin: -8px 0 10px 46px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}



.wcuuO {
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 5px solid #17a2b8 !important;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    opacity: .5;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
}
.fzjmLC, .bgPCoD {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px!important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: -20px 0 0px 15px!important;
    overflow: hidden;
    position: relative;
    padding: 10px!important;;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.bgPCoD {
    -webkit-animation: gZmgDU 0.3s ease forwards;
    animation: gZmgDU 0.3s ease forwards;
    background: #fff !important;
    border-radius: 0 18px 18px 18px!important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    color: #000!important;
    display: inline-block;
    font-size: 13px!important;
    max-width: 100% !important;
    margin: -20px 0 15px 15px!important;
    overflow: hidden;
    position: relative;
    padding: 10px!important;;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}
.kNrYDJ {
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 8px 6px 10px 6px!important;
    padding: 16px;
}
.hrBZJS {
    background: #f3f1f1!important;
    border-radius: 10px;
    box-shadow: 9px 9px 10px -11px rgba(0,0,0,0.75);
    font-family: monospace;
    overflow: hidden;
    position: relative;
    bottom: initial;
    top: initial;
    right: initial;
    left: initial;
    width: 400px;
    height: 625px;
    z-index: 999;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
}
.eDOvDv, .jgsmDM {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #17a2b8!important;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    fill: #fff;
    height: 56px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 10px;
    box-shadow: 9px 9px 10px -13px rgba(0,0,0,0.75);
}
.jVMajw {
    width: 420px !important;
    height: 620px !important;
    bottom: 20px !important;
    right: 20px !important;
}
.jeOWIe {
    width: 420px !important;
    height: 620px !important;
    bottom: 20px !important;
    right: 20px !important;
}

.gxaVzD {
    background: #f0f0f0;
}
.HrDCL {
    height: calc(570px - 112px) !important;
    background-image: url(../src/img/getimage.png) !important;
    background-size: 100% !important;
}
.hUMRbp {
    height: calc(570px - 112px) !important;
    background-image: url(../src/img/getimage.png) !important;
    background-size: 100% !important;
}
.gxaVzD {
    height: calc(620px - 112px) !important;
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
}
.brrKeP {
    margin: -20px 15px 10px 15px !important;
}
.fGPkxk, .gBpIQl {
    margin-left: 0px!important;
}
.enhgbB {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: -10px 15px 12px 15px !important;
    background: #fff;
    display: inline-block;
    padding: 10px 15px !important;
    border-radius: 10px !important;
}
.fxWvvr {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: -10px 10px 12px 15px !important;
    background: #fff;
    display: inline-block;
    padding: 10px 20px !important;
    border-radius: 10px !important;
}

@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
}

@keyframes shadow-pulse-big
{
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
    }
}

.bDElFG {
    animation: shadow-pulse 1s infinite;
}
.jrcSyQ {
    animation: shadow-pulse 1s infinite;
}
.chat-item {
    background: #fff!important;
    color: #000!important;
    font-size: 13px!important;
    padding: 10px 10px;
    border-radius: 18px;
    margin-right: 10px!important;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.35)!important;
    text-transform: uppercase;
    font-weight: 600;
}
.slots-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    padding: 10px 0;
}
.react-datepicker__day--selected {
    color: #000 !important;
    box-shadow: none !important;
}
.react-datepicker__day--selected:focus {
    outline:none;
}
.prev {
    margin-top: 16px;
}
.next {
    margin-top: 16px;
}
.doc-img {
    margin-left: 0;
}
.hBoqZI {
    font-size: 18px !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.hVbQid {
    font-size: 18px !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.resetStyle {
    cursor: pointer;
    display: block;
    position: relative;
    top: -600px;
    left: -40px;
    fill: #FFF;
    z-index: 10000;
    animation-delay: 5s;
    transition-delay: 5s;
}
.logoStyle {
    cursor: pointer;
    display: block;
    position: relative;
    top: -578px;
    z-index: 10000;
    left: -370px;
    fill: #FFF;
    animation-delay: 5s;
    transition-delay: 5s;
    display: none;
}
.rsc .rsc-header-title {
    position: relative;
}
.rsc .rsc-header-title:before  {
    position: absolute;
    content: "";
    left: -35px;
    top: -3px;
    background: url(../src/img/hospital-icn.png) no-repeat;
    background-size: contain;
    z-index: 9999999;
    width: 23px;
    height: 23px;

}
.react-datepicker__day {
    border: 1px solid #000;
    border-radius: 50%;

}
.fGPkxk:focus {
    outline: none;
}
button.book-btn {
    background: #17a2b8;
    border: 1px solid #17a2b8;
    padding: 7px 20px;
    border-radius: 30px;
    margin: 50px auto;
    display: table;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.appoinment-container {
    position: relative;
    background: #dee2e6!important;
    width: 100%!important;
    height: 474px !important;
    border: 1px solid #e8e8e8!important;
    box-shadow: -2px 9px 5px -10px rgba(0,0,0,0.75);
    border-radius: 10px;
    border: 2px solid #18a2b8;
}
.apo-header {
    align-items: center;
    display: flex;
    box-shadow: 0 0 9px -0.5px rgb(0 0 0 / 23%)!important;
    left: 0px;
    background-color: #abd4da !important;
    height: 50px!important;
    color: #000;
    align-items: center;
    display: flex;
    border-radius: 10px 10px 0 0;
}
.header-title {

    font-size: 16px!important;
}
.kATXlE, .fzjmLC, .brrKeP, .fGPkxk, .fkcGjN, .chat-item  {
    font-family: 'Montserrat', sans-serif !important;
}
.kATXlE, .bgPCoD, .dejyBq, .gBpIQl, .inbydY, .chat-item  {
    font-family: 'Montserrat', sans-serif !important;
}
.kATXlE {
    padding: 10px 12px !important;
    max-width: 100% !important;
    margin-left: 15px !important;
    margin-bottom: 0px !important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    background: #ffffff !important;
}
.dmkhbB {
    padding: 10px 12px !important;
    max-width: 100% !important;
    margin-left: 15px !important;
    margin-bottom: 5px !important;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    background: #ffffff !important;
}

.header-title {
    font-family: 'Montserrat', sans-serif !important;
}
.doc-meta .dep {
    font-family: 'Montserrat', sans-serif !important;
}
.doc-meta .name {
    font-family: 'Montserrat', sans-serif !important;
    font-weight:600 !important;
}
.doc-meta .qu {

    font-size: 10px!important;
    text-align: left;

   }
/* .doc-meta .qu {
    font-size:10px !important;
    font-family: 'Montserrat', sans-serif !important;
} */
.doc-meta .exp, .date-feild {
    font-family: 'Montserrat', sans-serif !important;
}
.tab .item {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
}
.slots-area .item {
    font-family: 'Montserrat', sans-serif !important;
}
.chat-item-long h4 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
}
.search-feild {
    padding: 0 10px;
}
.search-feild:focus {
    outline: none;
    border: none;
}

.react-datepicker__day {
    color: #fff;
    /* display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem; */
}
.kATXlE {
    font-size: 14px !important;
}
.dmkhbB {
    font-size: 14px !important;
}
.fzjmLC, .brrKeP, .fkcGjN, .brrKeP  {
    font-size: 14px !important;
}
.bgPCoD, .brrKeP, .inbydY, .brrKeP  {
    font-size: 14px !important;
}
.fGPkxk {
    font-size: 13px !important;
    border-radius: 18px 18px 18px 18px !important;
    float: left;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #000 !important;
}
.gBpIQl {
    font-size: 12px !important;
    border-radius: 18px 18px 18px 18px !important;
    float: left;
    padding: 5px 10px !important;
    background: #ffffff !important;
    border: 1px solid #000 !important;
}
.fGPkxk:hover {
    background: #00adee !important;
    color: #fff !important;
    border: 1px solid #00adee !important;
}
.gBpIQl:hover {
    background: #00adee !important;
    color: #fff !important;
    border: 1px solid #00adee !important;
}
.gBpIQl {
    font-size: 11px !important;
    border-radius: 18px 18px 18px 18px !important;
}
.eSbheu {
    float: left;
}
.react-datepicker__day {
    color: #000;
}
.iRzrAb {
    display: block !important;
}
.dTodAw {
    display: block !important;
    padding: 5px;
    margin: 0px 5px 5px 10px;
}
.search-box {
    width: 65%;
}
.kATXlE {
    display: inline-table;
}
.dmkhbB {
    display: inline-table;
}
.bDElFG {
    width: 75px !important;
    height: 75px !important;
    background:url(../src/img/pop-icon-04.png) !important;
    background-size: contain !important;
    bottom: 40px !important;
}
.jrcSyQ {
    background: #0062a8 !important;
}
select {
    color: #fff !important;
    width: 100%;
    background: #0062a8;
    padding: 10px;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid #0062a8;
    /* margin: 0 auto; */
    /* display: table; */
    font-size: 15px !important;
    font-weight: 600 !important;
}
select:focus {
    outline: none;
}
select option {
    width: 300px;
    font-size: 13px;
}
.eDOvDv {
    height: 46px !important;
    /* animation: myanimation 10s infinite; */
}
/* @keyframes myanimation {
  0% {background-color: red;}
  25%{background-color:yellow;}
  50%{background-color:green;}
  75%{background-color:brown;}
  100% {background-color: red;}
} */

.privacy-policy {

    width: 100%;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
}
.iWmPrn .input-submit input {
    width: 230px !important;
}
.doc-card {
    margin-bottom: 20px;
}
.contact-sect button.btn-cnt {
    border: none !important;
    background-color: transparent;
    padding: 0;
    text-transform: capitalize;
}
.contact-sect button.btn-cnt:focus {
    border: none !important;
    box-shadow: none;
    outline: none;
}
.logo-header img {
    margin-right: 10px;
    width: 35px;
    margin-left: 5px;
}
.rsc-float-button svg {
    display: none;
}
.dhsUgD {
    float: left;
}
.gBpIQl {
    float: left;
}
.eVthjO {
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;
}
.eVthjO svg {
    fill: #000 !important;
}
.eVthjO .logo-header h5 {
    color: #000;
}
.chat-item {
    text-align: center;
    margin-left: 0px;
    float: left;
    text-transform: capitalize;
    font-size: 11px !important;
    margin-bottom: 5px;
    padding: 4px 7px;
    border-radius: 18px;
    margin-right: 5px!important;
    box-shadow: none !important;
    border: 1px solid #000;
    font-weight: 100;
}
.location-main-div {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 12px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.kNrYDJ {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 15px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.bJCmFu {
    box-shadow: 0 0 10px -3px rgba(0,0,0,.5) !important;
    margin: 3px 15px 15px 15px !important;
    background: #fff !important;
    padding: 10px 10px !important;
    border-radius: 10px !important;
    text-align: center;
    display: flex !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center !important;
}
.location-div {
    display: contents;
}
.flag-select {
    padding-bottom: 1px !important;
    text-align: left;
    border: 1px solid #1782b8;
    border-right: none;
    border-radius: 4px 0px 0 4px;
}
.flag-select__option {
    margin: 1px 0 7px 0 !important;
}
.iNniHT .input-submit input {
    text-transform: capitalize;
}

.cYTrag .logo-header h5 {
    color: #000;
}

.cYTrag{
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;
}

.cYTrag svg {
    fill: #000 !important;

}
.kuqxww .input-submit input {
    width: 100%;
    text-transform: capitalize !important;
}
.iWmPrn .input-submit input {
    text-transform: capitalize !important;
}

.jYxGEJ{
    background-color: #1782B8;
    color: #fff;
    padding: 0.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff !important;
    border-bottom: 2px solid #c4cad0;

}

.jrcSyQ{

    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background: #6e48aa;
    bottom: 32px;
    border-radius: 100%;
    box-shadow: 0 12px 24px 0 rgba(0,0,0,0.15);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    fill: #fff;
    height: 56px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    right: 32px;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    width: 56px;
    z-index: 999;
}



.jrcSyQ{
    width: 75px !important;
    height: 75px !important;
    background: url(img/pop-icon-04.png) !important;
    background-size: contain !important;
    bottom: 40px !important;
    z-index: 99999 !important;
}

.jYxGEJ .logo-header h5{
    position: relative;
    top: 6px;
    font-family: 'Poppins';
    font-weight: bold;
}
.jYxGEJ .logo-header h5{
    color: #000;
}

svg {
    overflow: hidden;
    vertical-align: middle;
    fill: #000 !important;
}
.privacy-policy {
    border-radius: 10px 10px 10px 10px;
}

.dJJgye {
    width: 20px !important;
    height: 20px !important;
    display: block;
    padding: 0 !important;
    min-width: auto !important;
}
.hjdLDW {
    width: 20px !important;
    height: 20px !important;
    display: block;
    padding: 0 !important;
    min-width: auto !important;
}
.gOCww {
    display: block !important;
}
.jmCkbN {
    display: block !important;
    padding: 0 0 5px 0;
    margin: 5px 10px;
}
.loader-div {
    box-shadow: none !important;
    background: transparent !important;
}
.bJCmFu {

}

.slots-area .item {
    width: 45%;
    padding: 5px 3px;
    margin: 5px;
}
.bgPCoD, .brrKeP, .chat-item, .dejyBq, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-size: 14px !important;
}
.chat-item{
    font-size: 12px !important;
    text-transform: uppercase !important;
}
select option {
    font-size: 14px;
}
.cUWXFh textarea {
    height: 100px;
}
.cUWXFh button {
    background: #00aeef;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #00aeef;
}
.bgPCoD, .brrKeP, .dmkhbB, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-size: 14px!important;
    font-family: "Montserrat",sans-serif!important;
}
.gBpIQl {
    font-size: 12px !important;
}
.bgPCoD, .brrKeP, .dmkhbB, .fkcGjN, .fzjmLC, .inbydY, .kATXlE {
    font-family: "Montserrat",sans-serif!important;
}
@media only Screen and (max-width: 1370px) {
    .jeOWIe {
        height: 510px !important;
    }
    .gxaVzD {
        height: calc(510px - 112px) !important;
    }
    .resetStyle {
        top: -510px;
    }
    .jVMajw {
        height: 510px !important;
    }

}
@media only Screen and (max-width: 1920px) {
    .jVMajw {
        height: 455px !important;
    }
    .bkhdnw{
        height:  521px!important
    }
    .copy_rights{
        right: 35px;
    }
}
@media only Screen and (max-width: 350px) {
    .jVMajw {
        height: 455px !important;
    }
    .bkhdnw{
        height:  561px!important;
    }
    .copy_rights{
        right: 5px !important;
    }

}
@media only Screen and (max-width: 767px) {
    .kuqxww .input-submit input {
        width: 150px !important;
    }
    .fTBrVP .input-submit input {
        width: 170px !important;
    }
    .XYUYI .input-submit input {
        width: 170px !important;
    }
    .slots-area .item {
        margin-bottom: 15px;
    }
    .jeOWIe {
        width: 330px !important;
        border-radius: 20px !important;
        bottom: 10px !important;
        right: 10px !important;
    }
    .resetStyle {
        top: -490px;
        left: -15px;
    }
    .jVMajw {
        width: 310px !important;
        height: 476px !important;
        bottom: 20px !important;
        right: 20px !important;
        border-radius: 10px !important;
    }

    .fzjmLC, .brrKeP, .fkcGjN, .brrKeP {
        font-size: 13px !important;
    }
    .kATXlE {
        font-size: 13px !important;
    }
    .doc-meta .dep {
        font-size: 12px !important;
        font-weight: 600;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .doc-meta .qu {
        font-size: 9px!important;
        font-weight: bold;
    }
    .doc-meta .name {
        font-size: 10px;
    }
    .fGPkxk {
        font-size: 12px !important;
    }

    .appoinment-container {
        height: auto !important;
    }
    .chat-item-long h4 {
        font-family: 'Montserrat', sans-serif !important;
        font-size: 15px;
    }
    .doc-img {
        width: 70px;
        height: 70px;
        border-radius: 10px!important;
        margin-left: 0px;
    }
    .prev {
        margin-top: -16px;
    }

    .next {
        margin-top: -16px;
    }
    .search-box {
        width: 60%;
    }
    .empty_container {
        width: 15px;
    }
    .doc-card {
        margin-bottom: 0px !important;
    }
}
@media screen and (max-width: 568px) {
    .jeOWIe {
        bottom: 10px !important;
        right: 10px !important;
    }
    .sc-fzoLsD.bkhdnw.rsc-container {
        z-index: 16000163 !important;
    }
}
@media only Screen and (max-width: 320px) {
    .chat-item {
        font-size: 10px!important;
        text-transform: uppercase !important;
    }
    .resetStyle {
        top: -360px;
        left: -15px;
    }

    .slots-area .item {
        margin-bottom: 15px;
        width: 60%;
    }
    .copy_rights{
        font-size: 8px;
    }
    .jVMajw {
        width: 290px !important;
        height: 420px !important;
        bottom: 20px !important;
        right: 20px !important;
        border-radius: 10px !important;
    }

    .fzjmLC, .brrKeP, .fkcGjN, .brrKeP {
        font-size: 13px !important;
    }
    .kATXlE {
        font-size: 13px !important;
    }
    .fGPkxk {
        font-size: 12px !important;
    }
    .doc-meta .dep {
        font-size: 10px !important;;
    }
    .jeOWIe {
        width: 275px !important;
        height: 400px !important;
        border-radius: 10px;
        bottom: 10px !important;
        right: 10px !important;
    }

    .gxaVzD {
        height: calc(400px - 112px) !important;
    }
    .sc-fzoLsD.bkhdnw.rsc-container {
        z-index: 16000163 !important;
    }
}

.anchor{
    display: block;
    height: 115px; /*same height as header*/
    margin-top: -115px; /*same height as header*/
    visibility: hidden;
}

.loader_issue{
    background: #000 !important;
}

.infoText{
    color:#5f6368;
    font-size: 10px;
    text-transform:initial;
    text-align: left;
    float: left;
}
.surButton{
    display: table;
    margin: 5px auto;
    padding: 5px 10px !important;
    margin-top: 30px;
}
.inputTextArea{
    /* width:320px !important; */
}

.react-switch-bg
{
    margin-bottom: 10px !important;
}

.cxzqCi {
    border-bottom: 2px solid #c4cad0 !important;
    border-radius: 0 !important;
}



/*.copy_rights {*/
/*    z-index: 999999;*/
/*    position: fixed;*/
/*    right: 34px;*/
/*    bottom: 21px;*/
/*    font-size: 10px;*/
/*    color: #000;*/
/*    text-align: right;*/
/*    font-weight: 500;*/
/*    width: 100%;*/
/*    padding-bottom: 4px;*/
/*}*/


.copy_rights {
    z-index: 16000163;
    position: fixed;
    /*right: 31px;*/
    bottom: 21px;
    font-size: 10px;
    color: #000;
    text-align: right;
    font-weight: 500;
    width: 100%;
}
.HrDCL, .hUMRbp {
    height: 380px !important;
}

.gyJkjv {
    border-bottom: 2px solid #c4cad0 !important;
    border-radius: 0 !important;
}

.cYTrag .logo-header h5 {
    top: 0 !important;
    margin: 0 !important;
}

.new-message-input-field {
    margin-bottom: 135px !important;
}.send-message-button {
     bottom: 10vh;
 }

@media only Screen and (max-width: 1610px) {
    .send-message-button {
        bottom: 12vh !important;
    }
}
@media only Screen and (max-width: 1370px) {
    .send-message-button {
        bottom: 15vh !important;
    }
}


.sc-fzoXzr.cYTrag{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}



.footer_hide{
    display: none;
}