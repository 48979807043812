.rsc-footer{
    /*display: none;*/
}

.input_text{
    position: fixed;
    bottom: 24px !important;
    left: 0 !important;
    width: 100%;
}

.onlineChat{
    position: relative;
    /*left: -91px !important;*/
    width: 100%;
}
.sender_msg{
    display: flex;
}

.receiver_msg{
    display: flex;
    flex-flow: row-reverse;
}

.rsc-ts-image{
    width:40px;
    height:40px;
}

.agent_name{
    /* display: flex; */
    text-align: left !important;
    font-size: 10px;
}

.content_sender{
    padding: 4px;
    border-radius: 0px 10px 10px 10px;
    background: #ececec;
}

.content_receiver{
    padding: 4px;
    border-radius: 10px 10px 10px 1px;
    background: #ececec;
}


.userinput{
    display: flex;
    flex-direction: column;
}


.userinput input ,.userinput button {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}



.btZjys {
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    opacity: 1;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
    -webkit-appearance: none;
}
.lexRPv {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0;
}

.form_center{
    display: flex;
    justify-content: start;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 3px;
}
