body {
	position: relative;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #fff; /* fallback for old browsers */
}

.code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.rsc .rsc-header-title {
	margin-left: 40px;
	position: relative;
	top: 1px;
}

.input[disabled] {
	background: none;
	opacity: 0.9;
	cursor: not-allowed;
}
