.whatsapp-icon{

display: block;
position: absolute;
left: 15vh;
}

.n{
    color: #fff;
    padding-left: 10px;
    font-weight: 600;
    padding-top: 3px;
}
.y{
    padding: 4px 0px 4px 6px !important; 
}
.ul-swich-section {
    overflow: hidden;
    list-style: none;
    margin: 0 auto;
    display: table;
    padding: 0;
}
.ul-swich-section li {
    float: left;
    padding: 0 6px;
}
.ul-swich-section li span {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-top: 3px;
    display: inline-block;
}
